import React from 'react'
import '../css/Mission.css';
export default function serviceHeader() {
  return (
    <div className='mt-3 pt-1'>
       <div className='contactHeader mt-5'>
    <div className="container d-flex justify-content-center align-items-center h-100">
      <div>
        <h1 className='text-center textcolor' style={{ color: 'white', position: 'relative' }}>About EDCAT</h1>
        <p className='text-center' style={{ color: 'white', position: 'relative' }}>We believe in fostering partnerships built on engineering excellence</p>
      </div>
    </div>
  </div>
<div>
    
</div>
    </div>
  )
}
