import React from "react";
import '../css/sevice.css'

export default function sevice() {
  return (
    <div className="container pt-5">
      <p className="p-5 servicetextp1">
        EDACT is a global leader provides a full range of VDC/CAD consulting and
        engineering services for the AEC industry. Our range of services for the
        structural cover all stages of the project from design to facility
        management. Our design development support enables architects and
        engineering consultants to focus more on their core functions, leaving
        design upgradation and documentation to us. Our engineers collaborate
        with design engineers, general contractors, and trade contractors. We
        are offer standalone individual detailing services for architectural,
        structural. Preparation of fabrication shop drawings for offsite
        fabrication of precast & steel members as well as shop fit-outs are also
        among our offerings. Our engineering teams work proactively to identify
        potential issues and conflicts early in the project, saving clients both
        time and money. Collaborating closely with our clients, we ensure a deep
        understanding of their project goals and requirements. Our approach
        focuses on delivering customized, tailored services to achieve the best
        possible results.
      </p>
    </div>
  );
}
