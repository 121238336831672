import React from 'react'
import ContactHeader from '../components/contact/contactHeader.js';

export default function Contact() {
  return (
    <div>
      <ContactHeader/>
    </div>
  )
}
